///* ========================================================================
//   #Circle object
//   ======================================================================== */

.o-circle {
    border-radius: 100% !important;
    padding: 0 !important;
    width: 24px;
    height: 24px;
    @include flexCenter(inline);
}


/**
 * Size variants
 */
 .o-circle--medium {
    width: 28px;
    height: 28px;
}




/**
 * Color variants
 */ 
.o-circle--red {
    color: white;
    background-color: $color-base-red;
}