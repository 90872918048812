///* ========================================================================
//   #SITE LOGO
//   ======================================================================== */
.c-site-logo {
    display: inline-block;
    vertical-align: middle;
    width: 175px;
    height: 25px;

    @include mq($from: tablet) {
        width: 235px;
        height: 34px;
    }
}