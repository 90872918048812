///* ========================================================================
//   #Colors
//   ======================================================================== */
$colors: (
    '-base-blue': $color-base-blue,
    '-white': white,
    '-base-text': $color-base-text
);

/**
 * Generate color classes regarding $colors object
 */
@each $color-name, $color-val in $colors {
    .u-color#{$color-name} {
        color: $color-val;
    }
}