///* ========================================================================
//   #GRID
//   ======================================================================== */

/**
 * Grid system margin bottom compensation
 */
.o-grid {
    margin-bottom: - $grid-gutter-spacing-unit;

    .o-grid__cell {
        margin-bottom: $grid-gutter-spacing-unit;
    }
}

.o-grid--sm {
    margin-bottom: - $grid-gutter-spacing-unit * 0.5;

    .o-grid__cell {
        margin-bottom: $grid-gutter-spacing-unit * 0.5;
    }
}


// null: $grid-gutter-spacing-unit,
//     '--xs': $grid-gutter-spacing-unit,
//     '--sm': $grid-gutter-spacing-unit,
//     '--lg': $grid-gutter-spacing-unit,
//     '--xl': $grid-gutter-spacing-unit,
//     '--none': 0