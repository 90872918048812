/* ==========================================================================
   #HEADINGS
   ========================================================================== */
h1,
h2,
h3,
h4 {
    font-family: $base-bold-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-base-blue;
}