/* ==========================================================================
   #Page footer
   ========================================================================== */
.c-footer {
    padding: 40px 50px;
    background-color: $color-base-blue;
}

    .c-footer__logo {
        display: inline-block;
        width: 228px;
        margin-bottom: 8px; 
    }

    .c-footer__link {
        transform: rotate(180deg);
    }