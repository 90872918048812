///* ========================================================================
//   #TEXT ALIGN
//   ======================================================================== */
.u-text-center {
    text-align: center;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}

@include mq($until: tablet) {
    
    .u-text-center\@tablet {
        text-align: center;
    }
}