/* ==========================================================================
   #PAGE HEADER
   ========================================================================== */
   .c-header {
       padding: 18px 16px;
       background-color: white;
       position: fixed;
       top:     0;
       left:    0;
       right:   0;
       z-index: 50;
      //  border-bottom: 1px solid ;
       box-shadow: 1px 1px 3px rgba(#000, .2);
       will-change: transform;
      
       @include mq($from: tablet) {
         padding: 18px 50px;
       }
   }