///* ========================================================================
//   #WRAPPER
//   ======================================================================== */

$wrapper-offset:                12px;

.o-wrapper {
    margin: 0 auto;
    max-width: 100%;
    width: 1024px - 2*$wrapper-offset;
    padding: 0 $wrapper-offset;
}

/**
 * Wrapper size modifiers
 */
 .o-wrapper--small {
     width: 750px - 2*$wrapper-offset;
 }

 .o-wrapper--large {
     width: 1180px - 2*$wrapper-offset;
 }