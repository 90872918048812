///* ========================================================================
//   #PRODUCTS
//   ======================================================================== */

.c-products {
    padding-top: 20px;

    @include mq($from: desktop) {
        padding-top: 40px;
    }
}

    .c-products__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 348px;
        border: 1px solid rgba(33,29,112,0.19);
        transition: box-shadow 500ms;
        justify-content: space-between;

        footer {
            padding: 20px;
            font-size: 14px;
        }


        &:hover {
            box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }

        &.c-products__item--standalone {
            background-size: 118px auto !important;

            footer {
                padding: 34px;
            }
         
            &:hover {
                box-shadow: none;
                cursor: auto;
            }
        }
    }

        .c-products__content {
            padding: 20px;
            height: 230px;
            @include flexCenter(inline);
            
            img {
                object-fit: contain;
                height: 180px;
                width: 100%;;
            }

            .c-products__item--standalone & {
                align-items: start;
                padding: 34px;
            }
        }

        .c-products_price-tag {
            display: inline-block;
            border-radius: $global-radius;
            background-color: $color-base-blue;
            line-height: 15px;
            color: white;
            padding: 10px;
            font-size: 18px;
            font-family: $base-bold-font-family;
        }