/* ==========================================================================
   #TABLE ELEMENT
   ========================================================================== */
table {
    text-align: left;
}

    thead {
        background-color: $color-base-red;
    }

        tr {

            &:nth-child(even) {
                background-color: rgba($color-base-light-gray, .3);
            }
        }


    th {
        font-size: 12px;
        padding: 20px;
        color: white;
        font-family: $base-medium-font-family; 
    }

    td {
        padding: 15px;
        font-size: 14px;
        vertical-align: top;
    }