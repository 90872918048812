/* ==========================================================================
   #Page
   ========================================================================== */

   /**
    * This sticks page footer to bottom of the page
    */
.o-page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

    .o-page__content {
        flex: 1 0 auto;
    }

    .o-page__footer {
        flex-shrink: 0;
    }