///* ========================================================================
//   #Custom mixins
//   ======================================================================== */

/**
 * Use flex properties to center child element
 */
@mixin flexCenter($display:block) {

    @if ($display == inline) {
        display: inline-flex;
    } @else {
        display: flex;
    }
    
    align-items: center;
    justify-content: center;
}

@mixin verticalCenter {
    top: 50%;
    transform: translateY(-50%);
}