///* ========================================================================
//   #PRICE TAG
//   ======================================================================== */

.c-price-tag {
    display: inline-block;
    border-radius: $global-radius;
    background-color: $color-base-blue;
    height: 32px;
    line-height: 35px;
    color: white;
    padding: 0 10px;
    font-size: 13px;
    font-family: $base-bold-font-family;
    margin-right: 10px;
}


.c-price-tag--old-price {
    background-color: $color-base-light-gray;
    position: relative;

    &:after {
        position: absolute;
        display: block;
        content: '';
        height: 1px;
        @include verticalCenter;
        z-index: 10;
        background-color: $color-base-red;
        left: 5px;
        right: 5px;
    }
}


.c-price-tag--discount {
    background-color: $color-base-red;
}