///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.



// Standardise some UI treatments.

$global-radius:                 5px;
$global-transition:             all 240ms ease-out;
$grid-gutter-spacing-unit:      32px;
$global-font-size:              18px;
$global-link-color:             $color-base-blue;
$global-text-color:             $color-base-text;


$mq-breakpoints: (
    mobile:  480px,
    tablet:  740px,
    desktop: 980px,
    wide:    1300px,
);

/**
 * Grid gutter sizes
 */ 
 $inuit-flexgrid-spacing-sizes: (
    null: $grid-gutter-spacing-unit,
    '--xs': $grid-gutter-spacing-unit,
    '--sm': $grid-gutter-spacing-unit * 0.5,
    '--lg': $grid-gutter-spacing-unit,
    '--xl': $grid-gutter-spacing-unit,
    '--none': 0
);

//Base font families
$base-light-font-family:        'NowLight', sans-serif;
$base-regular-font-family:      'NowRegular', sans-serif;
$base-medium-font-family:       'NowMedium', sans-serif;
$base-bold-font-family:         'NowBold', sans-serif;

//Base heading sizes
$inuit-font-size-h1:  60px;
$inuit-font-size-h2:  30px;
$inuit-font-size-h3:  24px !default;
$inuit-font-size-h4:  20px !default;
$inuit-font-size-h5:  18px !default;
$inuit-font-size-h6:  16px !default;

