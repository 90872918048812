///* ========================================================================
//   #Font weight
//   ======================================================================== */
$fontsWeightData: (
    '-light': $base-light-font-family,
    '-regular': $base-regular-font-family,
    '-medium': $base-medium-font-family,
    '-bold': $base-bold-font-family
);

/**
 * Generate font weight classes regarding $fontsWeightData object
 */
@each $fontName, $fontFamily in $fontsWeightData {
    .u-font-weight#{$fontName} {
        font-family: $fontFamily !important;
    }
}
