///* ========================================================================
//   #Hero slider
//   ======================================================================== */

.c-hero-slider {
    background-color: lightgray;
    font-size: 0;
    min-height: 400px;

    @include mq($from: wide) { 
        min-height: 600px;
    }
}

    .c-hero-slider__item {
        height: 400px;
        position: relative;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @include mq($from: wide) { 
            height: 600px;
        }
    }

        .c-hero-slider__content {
            border-radius: $global-radius + 5px;
            background-color: $color-base-red;
            z-index: 20;
            padding: 50px 50px 40px 50px;
            max-width: 390px;
            color: white;
            background-size: 140px auto !important;

            @include mq($from: mobile) {
                left: 100px;
            }

            @include mq($from: wide) {
                margin-left: -570px;
                left: 50%;
            }
        }

            .c-hero-slider__price {
                font-size: 48px;
                font-family: $base-bold-font-family;
                margin-bottom: 0;
                line-height: 1.2;
            }

            .c-hero-slider__description {
                font-size: 18px;
                font-family: $base-medium-font-family;
                margin-bottom: 18px;
            }

    .c-hero-slider__arrow,
    .c-hero-slider__content {
        position: absolute;
        @include verticalCenter;
    }

    .c-hero-slider__arrow {
        
        z-index: 20;
        @include flexCenter(inline);
        border-radius: 100%;
        height: 44px;
        width: 44px;
        background-color: #fff;
        box-shadow: 0 6px 8px 0px rgba(0,0,0,0.2);
        opacity: 0.9;
        transition: $global-transition;
        color: $color-base-blue;


        &.c-hero-slider__arrow--prev {
            left: 30px;

            .o-icon {
                transform: rotate(180deg)
            }
            
        }

        &.c-hero-slider__arrow--next {
            right: 30px;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }