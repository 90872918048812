/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 *    buttons).
 * 3. Set button's icon regarding its parent.
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

 .c-btn {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    text-align: center; /* [4] */
    margin: 0; /* [4] */
    cursor: pointer; /* [5] */
    padding: 0 14px;
    transition: $global-transition;
    border-radius: $global-radius;
    font-family: $base-medium-font-family;
    position: relative; /* [3] */
    background-color: transparent;
    border: {
        style: solid;
        width: 1px;
        color: transparent;
    }
  }

    .c-btn__icon {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        @include flexCenter;
        @include verticalCenter;
        color: white;
    }
  
  
  
  

  /* Style variants
     ========================================================================== */
  
  .c-btn--primary {
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-size: 10px;
    color: white;
    text-transform: uppercase;

    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none; /* [4] */
    }
  }
  



  /* Color variants
     ========================================================================== */
.c-btn--red {
    background-color: $color-base-red;
    border-color: $color-base-red;

    .c-btn__icon {
        background-color: $color-base-dark-red;
    }

    &:hover,
    &:focus {
      background-color: darken($color-base-red, 10%);
      border-color: darken($color-base-red, 10%);
    }
}


.c-btn--blue {
    background-color: $color-base-blue;
    border-color: $color-base-blue;


    .c-btn__icon {
        background-color: $color-base-red;
    }

    &:hover,
    &:focus {
      background-color: darken($color-base-blue, 10%);
      border-color: darken($color-base-blue, 10%);
    }

}

.c-btn--white {border-color: white;}




  /* Size variants
     ========================================================================== */
  
  .c-btn--small {
    padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
  }
  
  .c-btn--large {
    padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
  }
  
  
  
  
  
  /* Ghost buttons
     ========================================================================== */
  
  /**
   * Ghost buttons have see-through backgrounds and are bordered.
   */
  
  $btn-ghost-border-width: 1px;
  
  .c-btn--ghost {
    // border: $btn-ghost-border-width solid currentColor;
  
    &,
    &:hover,
    &:active,
    &:focus {
      background: none;
    }
  
  
    &.c-btn--red {
      color: $color-base-red;
  
      &:hover,
      &:focus {
        color: darken($color-base-red, 10%);
      }
  
    }
  
    &.c-btn--blue {
      color: $color-base-blue;
  
      &:hover,
      &:focus {
        color: darken($color-base-blue, 10%);
      }
  
    }
  
    &.c-btn--white {
      color: #fff;
  
      &:hover,
      &:focus {
        color: #fff;
      }
  
    }
  
  }




  /* If button has icon set position of icon
     ========================================================================== */

     .c-btn--icon-right {
         padding-right: 40px;

        .c-btn__icon {
            right: 6px;
        }
     }


     .c-btn--icon-left {
        padding-left: 40px;

       .c-btn__icon {
           left: 6px;
       }
    }