/* ==========================================================================
   #FIGURE ELEMENT
   ========================================================================== */
figure {
    border: 1px solid $color-base-light-gray;
    border-radius: $global-radius;
    margin: 0 0 26px 0;
    padding: 20px;

    img {
        width: 100%;
    }

    @include mq($from: tablet) {
        margin: 0 0 46px 0;
        padding: 80px;
    }
}