///* ========================================================================
//   #SECTION
//   ======================================================================== */

.c-section {
    padding: 36px 0;
    background-size: cover;
    background-position: center center;

    @include mq($from: tablet) {
        padding: 42px 0;
    }

    @include mq($from: wide) {
        padding: 80px 0;
        min-height: 600px;
        display: flex;
        align-items: center;
    }
}

    .c-section__content {
        
        .c-section--center & {
            align-self: center;
        }
    }

        .c-section__img {
            width: 100%;
            height: auto;
        }
