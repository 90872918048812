///* ========================================================================
//   #Font size
//   ======================================================================== */
$fontsData: (
    '-tiny': 10px,
    '-xsmall': 12px,
    '-small': 14px,
    '-normal': 16px,
    '-large': 20px,
    '-xlarge': 24px
);

/**
 * Generate font size classes regarding $fontsData object
 */
@each $fontName, $fontSize in $fontsData {
    .u-font-size#{$fontName} {
        font-size: $fontSize !important;
    }
}