///* ========================================================================
//   #RESPONSIVE VISIBILITY
//   ======================================================================== */

/**
 * Make elements visible or hidden regarding some break points
 */
 .u-hide\@tablet {

    @include mq($until: tablet) {
        display: none;
    }
 }