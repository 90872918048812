/* ==========================================================================
   #Drawer
   ========================================================================== */

.c-drawer {
    position: fixed;
    z-index: 60;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(#000, .5);
    opacity: 0;
    visibility: hidden;

    &.c-drawer--open {
        transition: opacity .18s ease-out;
        visibility: visible;
        opacity: 1;
        
    }
}

    .c-drawer__close,
    .c-drawer__content {
        position: absolute;
    }

    .c-drawer__close {
        z-index: 20;
        right:  0;
        top:    0;
        color: $color-base-blue;
        padding: 10px;

        &:hover {
            color: $color-base-red;
        }

        @include mq($from: tablet) {
            right:  15px;
            top:    15px;
        }
    }

    .c-drawer__content {
        background-color: #fff;
        padding: 30px;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 10;
        width: 805px;
        max-width: 100%;
        overflow-y: auto;
        transform: translateX(100%);

        .c-drawer--open & {
            transform: translateX(0);
            transition: transform .18s ease-out .2s;
        }

        @include mq($from: tablet) {
            padding: 60px;
        }
    }

        .c-drawer__map {
            
            img {
                width: 100%;
            }
        }