///* ========================================================================
//   #SITE NAVIGATION
//   ======================================================================== */
.c-nav {
    position: absolute;
    top:100%;
    background-color: white;
    left: 0; 
    width: 100%;
    transform: translateX(-100%);
    transition: all .24s ease-out;
    box-shadow: inset 0px 1px 3px rgba(#000, .2);
    opacity: 0; 

    a {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        font-family: $base-medium-font-family;
        font-weight: 400;
        padding: 20px 16px;
        color: $color-base-blue;
        display: block;
    }

    &.is-open {
        transform: translateX(0);
        opacity: 1; 
    }

    @include mq($from: 1120px) {
        white-space: nowrap;
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inherit;
        width: auto;
        opacity: 1;
        box-shadow: none;

        a {
            display: inline-block;
            padding: 20px;

        }
    }
}