/* ==========================================================================
   #BURGER MENU
   ========================================================================== */
 /* Icon 1 */

.c-menu-btn {
    width: 27px;
    height: 17px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: $color-base-blue;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 16px;
        }
    }

    &.is-open {

        span {

            &:nth-child(1) {
                top: 8px;
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                opacity: 0;
                left: -60px;
            }

            &:nth-child(3) {
                top: 8px;
                transform: rotate(-135deg);
            }
        }
    }

    @include mq($from: 1120px) {
        display: none;
    }
  }